body,
html,
#root {
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
